<template>
  <basic-container
    isCard
    :body-style="{ padding: '0', height: '100%' }"
    class="h-100p"
  >
    <el-container class="h-100p">
      <el-header height="50px" class="lh-50 bor-b">
        <basic-page-header
          @back="back"
          class="lh-50"
          @click="back"
          icon="el-icon-download"
        ></basic-page-header>
      </el-header>

      <el-container style="height: calc(100% - 50px)">
        <el-main class="ov-a bor-r">
          <div class="pad-b-10 mar-t-30">
            <el-form
              class="pad-10"
              :model="ruleForm"
              ref="ruleForm"
              label-width="90px"
              size="small"
              labelPosition="right"
              :rules="ruleModel"
            >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="名称" prop="name">
                    <el-input
                      placeholder="请输入节目名称"
                      v-model="ruleForm.name"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="发布周期" prop="mode">
                    <el-radio-group v-model="ruleForm.mode" size="small">
                      <el-radio-button label="daily">按天</el-radio-button>
                      <el-radio-button label="weekly">按周</el-radio-button>
                      <el-radio-button label="loop">轮播</el-radio-button>
                      <el-radio-button label="selfDefine"
                        >自定义</el-radio-button
                      >
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="展示有效期" prop="name">
                    <template>
                      <el-col :span="6" class="pad-l-0">
                        <el-date-picker
                          v-model="ruleForm.startTime"
                          type="datetime"
                          placeholder="选择开始时间"
                        >
                        </el-date-picker>
                      </el-col>
                      <el-col :span="6">
                        <el-date-picker
                          v-model="ruleForm.endTime"
                          type="datetime"
                          placeholder="选择结束时间"
                          :picker-options="{
                            minTime: ruleForm.startTime,
                          }"
                        >
                        </el-date-picker>
                      </el-col>
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="选择节目" prop="publishProgramIds">
                    <el-select
                      v-model="ruleForm.publishProgramIds"
                      multiple
                      filterable
                      remote
                      clearable
                      reserve-keyword
                      default-first-option
                      placeholder="请输入节目名称"
                      :remote-method="remoteMethodP"
                      @change="changeProgram"
                    >
                      <el-option
                        v-for="item in programList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="12"
                  class="mar-t-10f"
                  v-if="ruleForm.publishProgramNames"
                >
                  <template
                    v-for="(item, index) of ruleForm.publishProgramNames"
                  >
                    <div class="roomtitle" :key="index">
                      {{ item }}

                      <el-popover
                        placement="right"
                        width="400"
                        trigger="click"
                        @show="getPic(item, index)"
                      >
                        <div style="width: 100px">
                          <template v-if="imgform.imgUrl.length > 0">
                            <avue-form
                              class="mar-t-20"
                              :option="option1"
                              v-model="imgform"
                            ></avue-form>
                          </template>
                        </div>
                        <i slot="reference" class="el-icon-view"></i>
                      </el-popover>
                      <i
                        class="el-icon-close"
                        @click="deletePublishProgram(item, index)"
                      ></i>
                    </div>
                  </template>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="选择设备" prop="publishDeviceIds" å>
                    <el-select
                      v-model="ruleForm.publishDeviceIds"
                      multiple
                      filterable
                      remote
                      clearable
                      reserve-keyword
                      default-first-option
                      placeholder="请输入设备名称"
                      :remote-method="remoteMethod"
                      :loading="remoteLoad"
                      @change="changeDevice"
                    >
                      <el-option
                        v-for="item in deviceList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="12"
                  class="mar-t-10f"
                  v-if="ruleForm.publishDeviceNames"
                >
                  <template
                    v-for="(item, index) of ruleForm.publishDeviceNames"
                  >
                    <div class="roomtitle">
                      {{ item }}
                      <i
                        class="el-icon-close"
                        @click="deleteDeviceName(item, index)"
                      ></i>
                    </div>
                  </template>
                </el-col>
                <el-col
                  :span="24"
                  class="pad-r-0 mar-t-10"
                  v-if="ruleForm.type == 3"
                >
                  <el-form-item label="申请说明" prop="reason">
                    <el-input
                      type="textarea"
                      :rows="5"
                      placeholder="请输入申请说明"
                      v-model="ruleForm.reason"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="btnClass mar-l-40p">
              <el-button
                v-if="ruleForm.approvalStatus != 1"
                size="small"
                class="mar-t-10 mar-r-5"
                type="primary"
                @click="save(1)"
                >暂存</el-button
              >
              <el-button
                size="small"
                class="mar-t-10 mar-r-5"
                type="primary"
                @click="save(2)"
                >发布</el-button
              >

              <el-button
                size="small"
                class="mar-t-10 mar-r-10"
                type=""
                @click="back"
                >取消</el-button
              >
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </basic-container>
</template>

<script>
import standardService from "@/api/metadata/standard";
import {
  getMetaCommonInfo,
  getPicListByIds,
  updateMetaCommon,
  saveMetaCommon,
  getMetaCommonList,
  getMetaCommonTree,
} from "@/api/busiMode/metaCommon";
import { publishscheduleSubmit } from "@/api/visitor/visitor";
import { mapGetters, mapState } from "vuex";
import { dateFormat } from "@/util/date";
import * as dictbizService from "@/api/system/dictbiz";

export default {
  data() {
    return {
      ruleModel: {},
      ruleForm: {
        publishDeviceIds: [],
        publishProgramIds: [],
        mode: "daily",
      },
      remoteLoad: false,
      model: "wisdom_park.wp_publish_schedule",
      devModel: "wisdom_park.wp_publish_device",
      programModel: "wisdom_park.wp_publish_program",
      deviceList: [],
      programList: [],
      imgform: {
        imgUrl: [],
      },
      option1: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        disabled: true,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    type: {
      immediate: true,
      handler(val) {},
    },
  },
  mounted() {
    this.queryByModelCode();
    this.getMetaCommonList();
    if (this.$router.currentRoute.query.publishId) {
      this.ruleForm.id = this.$router.currentRoute.query.publishId;
      this.getMetaCommonInfo();
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  props: {},
  methods: {
    back() {
      this.$router.push({
        path: "/information/publish",
      });
    },
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res;
      });
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },

    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.ruleForm.id,
      };
      getMetaCommonInfo(this.model, obj).then((res) => {
        if (res.data.code == 200) {
          let obj = {};
          Object.keys(res.data.data).forEach((ele) => {
            obj[this.toHump(ele)] = res.data.data[ele];
          });

          if (obj.startTime) {
            obj.startTime = new Date(obj.startTime);
          }
          if (obj.publishDeviceIds) {
            obj.publishDeviceIds = obj.publishDeviceIds.split(",");
          }
          if (obj.publishDeviceNames) {
            obj.publishDeviceNames = obj.publishDeviceNames.split(",");
          }
          if (obj.publishProgramIds) {
            obj.publishProgramIds = obj.publishProgramIds.split(",");
          }
          if (obj.publishProgramNames) {
            obj.publishProgramNames = obj.publishProgramNames.split(",");
          }
          this.ruleForm = obj;
        }
      });
    },
    getPic(val, i) {
      console.log(this.ruleForm.publishProgramIds[i]);
      if (this.ruleForm.publishProgramIds[i]) {
        let file = "";
        this.programList.forEach((p) => {
          if (p.id == this.ruleForm.publishProgramIds[i]) {
            file = p.file_url;
            return;
          }
        });
        if (file) {
          getPicListByIds(file).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              let arr = [];
              res.data.data.forEach((pic) => {
                arr.push({
                  label: pic.id,
                  value: pic.link,
                });
              });
              this.imgform.imgUrl = arr;
            }
          });
        }
      }
    },
    remoteMethod(val) {
      this.remoteLoad = true;
      this.getDeviceList(val);
    },
    remoteMethodP(val) {
      this.getProgramList(val);
    },
    getDeviceList(val) {
      this.remoteLoad = true;
      let body = {
        current: 1,
        size: 500,
      };
      body.wheres = [{ field: "name", op: "LIKE", value: val }];
      getMetaCommonList(this.devModel, body).then((res) => {
        let data = res.data.data;
        this.deviceList = data.records;
        this.remoteLoad = false;
      });
    },
    getProgramList(val) {
      let body = {
        current: 1,
        size: 500,
      };
      body.wheres = [{ field: "name", op: "LIKE", value: val }];
      getMetaCommonList(this.programModel, body).then((res) => {
        if (res.data.code == 200) {
          const data = res.data.data;
          this.programList = data.records;
        }
      });
    },
    getMetaCommonList() {
      let body = {
        current: 1,
        size: 500,
      };
      getMetaCommonList(this.devModel, body).then((res) => {
        if (res.data.code == 200) {
          const data = res.data.data;
          this.deviceList = data.records;
        }
      });
      getMetaCommonList(this.programModel, body).then((res) => {
        if (res.data.code == 200) {
          const data = res.data.data;
          this.programList = data.records;
        }
      });
    },
    changeProgram(val) {
      let names = [];
      let ids = [];
      this.programList.forEach((e) => {
        val.forEach((v) => {
          if (e.id == v) {
            ids.push(e.id);
            names.push(e.name);
          }
        });
      });
      this.ruleForm.publishProgramNames = names;
      this.ruleForm.publishProgramIds = ids;
    },

    deletePublishProgram(item, index) {
      console.log(index);
      this.ruleForm.publishProgramNames.splice(index, 1);
      this.ruleForm.publishProgramIds.splice(index, 1);
    },
    changeDevice(val) {
      let names = [];
      let ids = [];
      this.deviceList.forEach((e) => {
        val.forEach((v) => {
          if (e.id == v) {
            ids.push(e.id);
            names.push(e.name);
          }
        });
      });
      this.ruleForm.publishDeviceNames = names;
      this.ruleForm.publishDeviceIds = ids;
    },

    deleteDeviceName(item, index) {
      this.ruleForm.publishDeviceNames.splice(index, 1);
      this.ruleForm.publishDeviceIds.splice(index, 1);
    },

    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
    save(val) {
      Promise.all([this.submit()]).then((forms) => {
        if (this.ruleForm.publishDeviceIds) {
          let ids = [];
          this.ruleForm.publishDeviceIds.forEach((e) => {
            ids.push(e + "");
          });
          this.ruleForm.publishDeviceIds = ids;
        }
        if (this.ruleForm.startTime) {
          this.ruleForm.startTime = dateFormat(
            new Date(this.ruleForm.startTime),
            "yyyy-MM-dd hh:mm:ss"
          );
        }
        if (this.ruleForm.mode) {
          let name = "";
          if (this.ruleForm.mode == "daily") {
            name = "天";
          } else if (this.ruleForm.mode == "weekly") {
            name = "周";
          } else if (this.ruleForm.mode == "loop") {
            name = "轮播";
          } else if (this.ruleForm.mode == "selfDefine") {
            name = "自定义";
          }
          this.ruleForm.mode_name = name;
        }
        if (this.ruleForm.endTime) {
          this.ruleForm.endTime = dateFormat(
            new Date(this.ruleForm.endTime),
            "yyyy-MM-dd hh:mm:ss"
          );
        }
        let saveForm = JSON.parse(JSON.stringify(this.ruleForm));
        if (!saveForm.approvalStatus) {
          saveForm.approvalStatus = val;
        }
        if (saveForm.publishDeviceIds) {
          saveForm.publishDeviceIds = saveForm.publishDeviceIds.toString();
        }
        if (saveForm.publishDeviceNames) {
          saveForm.publishDeviceNames = saveForm.publishDeviceNames.toString();
        }
        if (saveForm.publishDeviceNames) {
          saveForm.publishDeviceNames = saveForm.publishDeviceNames.toString();
        }
        if (saveForm.publishProgramIds) {
          saveForm.publishProgramIds = saveForm.publishProgramIds.toString();
        }
        if (saveForm.publishProgramNames) {
          saveForm.publishProgramNames =
            saveForm.publishProgramNames.toString();
        }
        if (saveForm.id) {
          publishscheduleSubmit(saveForm).then((res) => {
            if (res.data.code == 200) {
              if (val == 1) {
                this.$message.success("您的信息已经帮您暂存成功");
              }
              if (val == 2) {
                this.$router.push({ path: "/information/feedback" });
              }
            }
          });
        } else {
          publishscheduleSubmit(saveForm).then((res) => {
            if (res.data.code == 200) {
              if (val == 1) {
                this.$message.success("您的信息已经帮您暂存成功");
              }
              if (val == 2) {
                this.$router.push({ path: "/information/feedback" });
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-select__tags {
  height: 32px;
  .el-tag--info {
    display: none;
  }
}
.roomtitle {
  border: 1px solid #1976d3;
  padding: 5px 10px;
  height: auto;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  margin-left: 10px;
  margin-top: 10px;
  i {
    color: #1976d3;
    cursor: pointer;
    padding: 0px 3px 0px 5px;
  }
}
.drbtn {
  position: absolute;
  right: 20px;
  top: 10px;
}
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}

/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
  position: absolute;
  bottom: 10px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-cascader {
  width: 100%;
}
</style>
